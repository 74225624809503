.cards{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top:10px;
    padding: 10px;
    border:1px solid white;
    background-color:black !important;
    color: antiquewhite;
}

.links{
    display: flex;
    flex-direction: column;
}

.links p{
    display: inline;
}

.padding-40{
    padding-left:40px;
}
@media screen and (max-width: 500px) {
    .cards{
        background-color: rgb(235, 238, 240);
    }
}