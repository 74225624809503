#redirect{
    text-align: center !important;
    font-size: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#redurlcontainer
{
    margin: 0px;
    width: 100%;
    height: 100vh;
    background-color: black;
    color: aqua;
}

.nolinkfound  {
    color: white !important;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.nolinkfound a {
    color: aqua !important;
}