.middle {
    justify-content: center;
    color: red;
}

.red {
    color:red;
}

div {
    margin: 0 auto;
    text-align: left;
}

label {
    display:inline-block;
    width:150px;
    height:20px;
    padding-left: 20px;
}

.center {
    text-align: center;
    display: block;
    margin: 0 auto;
}

input {
    width:250px;
    height: 30px;
}
.authbutton {
    color: black !important;
    background-color: greenyellow;
    border-radius:5%;
    height:40px;
    width: 110px;
    padding:5px;
    font-weight: bold;
}

.authbutton:hover{
    cursor: grab;
    background-color:red;
    color:whitesmoke !important;
    padding-left: 10px;
    padding-right: 10px;
}
.authbutton:active{
    padding-top: 10px;
    color:black !important;
}

