.taskbar {
    margin: 0px;
    padding: 0px;
    height:50px;
    width: 100%;
    background-color:black;
    border: 1px solid aqua;
    display: flex;
    justify-content: space-evenly; 
}

.taskbar a{
    margin-top: 15px;
    display: inline-block;
    background: transparent;
    color: aqua !important;
    text-decoration: none;
    font-weight: bold;
}

.taskbar a:focus {
    color: black !important;
    background-color: gray;
    padding: 15px !important;
    margin: 0px !important;
}

.taskbar a:hover {
    padding-top: 5px;
    color: white !important;
}
