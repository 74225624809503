* {
    font-family: sans-serif, arial;
}
body{
    margin:0px;
    padding:0px;
    background-color:black;
    color:white;
}

a {
    color:rgb(208, 231, 252);
}