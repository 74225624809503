.middle {
    justify-content: center;
    color: red;
}
#homeAll {
    margin-top: 0px !important;
    width: 100%;
    height: 100vh;
}
#homeAll h3 {
    margin-top: 0px;
    padding-top: 15px;
}

.red {
    color:red
}

.homeimg{
    width: 30% !important;
    height: 30% !important;
}

@media screen and (max-width: 600px) {
    .homeimg{
        width: 90% !important;
        height: 90% !important;
    }
}

@media screen and (min-width: 780px) {
    .homeimg{
        width: 40% !important;
        height: 40% !important;
    }
}