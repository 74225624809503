.loadingimg
{
    margin: 0px auto;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}
.loadingContainer{
    width: 100% !important;
    height: 100% !important;
    
}

.loadingtext
{
    text-align: center !important;
    font-size: 20px;
    position: fixed;
    top: 47.5%;
    left: 50%;
    transform: translate(-50%, -50%);
}